const GIFT_DB = require('@/data/gifts.json');

export default {
  async getGifts () {
    return Promise.resolve(GIFT_DB);
  },
  async getGift (code) {
    if (!code || code === '') {
      return Promise.reject(new Error(`Code invalide`));
    }

    const gifts = await this.getGifts();

    if (!gifts || !gifts.length) return Promise.reject(new Error('Oups, ton code ne correspond à aucun 🎁 !'));

    const gift = gifts.find(gift => gift.code.toLowerCase() === code.toLowerCase());

    if (!gift) return Promise.reject(new Error('Oups, ton code ne correspond à aucun 🎁 !'));

    return Promise.resolve(gift);
  },
  async isValidGift (code, secret) {
    if (!code || code === '' || !secret || secret === '') {
      return Promise.reject(new Error(`Oups, je n'arrive pas à débloquer ton 🎁 !`));
    }

    let gift = null;

    try {
      gift = await this.getGift(code);
    } catch (e) {
      return Promise.reject(e);
    }

    if (gift.secret.toLowerCase() === secret.toLowerCase()) return Promise.resolve(true);

    return Promise.reject(new Error('Oups, ton secret ne semble pas bon !'))
  },
  async getNextStepForGift (code) {
    let gift = null;

    try {
      gift = await this.getGift(code);
    } catch (e) {
      return Promise.reject(e.message);
    }

    if (!gift || !gift.steps) {
      return Promise.reject(new Error(`Oups, il n'y a pas de contenu sur ce cadeau`));
    }

    const nextStep = gift.steps
      .filter(gift => gift.date && new Date(gift.date) > Date.now())
      .reduce((nextGift, currentGift) => {
        if (!nextGift) return currentGift;
        if (!currentGift.date) return nextGift;

        if (new Date(currentGift.date) < new Date(nextGift.date)) {
          return currentGift;
        }

        return nextGift;
      }, null);

    return Promise.resolve(nextStep);
  },
  async getPassedStepsForGift (code) {
    let gift = null;

    try {
      gift = await this.getGift(code);
    } catch (e) {
      return Promise.reject(e.message);
    }

    if (!gift || !gift.steps) {
      return Promise.reject(new Error(`Oups, il n'y a pas de contenu sur ce cadeau`));
    }

    const passedStep = gift.steps.filter(gift => gift.date && new Date(gift.date) < Date.now());

    return Promise.resolve(passedStep);
  }
}
